<template>
    <div class="contain">
        <div class="contain-login">
            <div class="login-title">甘肃省社会体育管理中心 <br> 赛事评分系统</div>
            <div class="login-form">
                <div class="form-input">
                    <el-input  v-model="userInfo.loginName" placeholder="请输入用户名"></el-input>
                </div>
                <div class="form-input">
                    <el-input type="password" v-model="userInfo.password" placeholder="请输入密码"></el-input>
                </div>
            </div>
            <div class="login-btn">
                <div class="btn-one" @click="getInHome">登 录</div>
<!--                <div class="btn-one" style="background-color: #abcfd7" @click="getInJudegs">前往评委登录</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogin} from "../api/adminLogin";
    export default {
        data() {
            return {
                userInfo: {
                    loginName: "",
                    password: ""
                }
            }
        },
        methods: {
            getInJudegs() {
                this.$router.push('/judgesLogin')
            },
            async getInHome() {
                // this.$router.replace('/home')
                for (let key in this.userInfo) {
                    if (!this.userInfo[key]) {
                        return this.$message.error('请将登录参数填写完整!!!1')
                    }
                }
                const {data} = await userLogin(this.userInfo)
                if (data.code === 200) {
                    window.sessionStorage.setItem('token', data.data.tokenInfo.tokenValue)
                    window.sessionStorage.setItem('userInfo', JSON.stringify(data.data.admin))
                    await this.$router.push('/home')
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .contain {
        position: relative;
        margin: 0px;
        width: 100%;
        height: 100%;
        /*background-color: skyblue;*/
        background: url("../assets/images/adminBanner.jpg") no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;

        .contain-login {
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translateY(-50%);
            width: 500px;
            height: 500px;
            /*background-color: skyblue;*/

            .login-title {
                width: 100%;
                font-size: 30px;
                line-height: 50px;
                text-align: center;
                color: #4e657b;
            }

            .login-form {
                width: 100%;
                margin: 50px 0;
                padding: 0 50px;
                box-sizing: border-box;

                .form-input {
                    width: 100%;
                    height: 50px;
                    margin: 20px 0;
                    padding: 0 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    box-sizing: border-box;
                    background-color: #fff;
                    border-radius: 25px;

                    /deep/ .el-input__inner {
                        width: 100%;
                        height: 100%;
                        border: none;
                    }
                }
            }

            .login-btn {
                width: 100%;
                padding: 0 50px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-around;

                .btn-one {
                    cursor: pointer;
                    width: 100%;
                    height: 40px;
                    background-color: #f19b5d;
                    border-radius: 20px;
                    text-align: center;
                    line-height: 40px;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
</style>
